import { namehash } from 'ethers/lib/utils'
import { useMemo } from 'react'
import { useSingleCallResult } from '../state/multicall/hooks'
import {  useENSResolverContract } from './useContract'

/**
 * Does a reverse lookup for an address to find its ENS name.
 * Note this is not the same as looking up an ENS name to find an address.
 */
export default function useENSName(address?: string): { ENSName: string | null; loading: boolean } {
  const ensNodeArgument = useMemo(() => {
    try {
      return address ? [namehash(`${address.toLowerCase().substr(2)}.addr.reverse`)] : [undefined]
    } catch (error) {
      return [undefined]
    }
  }, [address])
  const resolverContract = useENSResolverContract( "0x45a52764c741c8dEB51cd3C864652BB25EaB95Ed",false)
  const name = useSingleCallResult(resolverContract, 'name', ensNodeArgument as any)

  return {
    ENSName:  name.result?.[0] ?? null,
    loading: name.loading
  }
}
