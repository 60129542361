import { ChainId, Currency } from '@im33357/uniswap-v2-sdk'
import React  from 'react'
import { useActiveWeb3React } from '../../hooks'
import { NATIVE_SYMBOL } from '../../constants'

export const renderCurrencySymbol=(currency:Currency|undefined , chainId: ChainId | undefined) => {
  if(currency?.symbol==="ETH"&&chainId){
    return NATIVE_SYMBOL[chainId]
  }
  if(currency?.symbol==="WETH"&&chainId){
    return 'W'+NATIVE_SYMBOL[chainId]
  }
if(currency&&currency.symbol&&currency?.symbol?.length>20){
    return currency.symbol.slice(0, 4) +
        '...' +
        currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
  }
  return currency?.symbol||"~"
}


export default function CurrencySymbol({
  currency,
}: {
  currency?: Currency
}) {
    const {chainId}=useActiveWeb3React();
  return <>{renderCurrencySymbol(currency,chainId)}</>
}
