import { ChainId } from '@im33357/uniswap-v2-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  // [ChainId.SEPOLIA]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  // [ChainId.BASE]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.BASE]:'0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.FANTOM]:'0xcA11bde05977b3631167028862bE2a173976CA11'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
