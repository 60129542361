import { createReducer } from '@reduxjs/toolkit'
import { getVersionUpgrade, VersionUpgrade } from '@uniswap/token-lists'
import { TokenList } from '@uniswap/token-lists/dist/types'
import { DEFAULT_LIST_OF_LISTS, DEFAULT_TOKEN_LIST_URL } from '../../constants/lists'
import { updateVersion } from '../global/actions'
import { acceptListUpdate, addList, fetchTokenList, removeList, selectList } from './actions'

const MAGESWAP_DEFAULT_LIST={
  "name": "Mageswap Default",
  "timestamp": "2023-09-18T07:59:40.684Z",
  "version": {
    "major": 2,
    "minor": 0,
    "patch": 1
  },
  "tags": {},
  "logoURI": "https://github.com/lilesper/lilesper.github.io/blob/main/assets/images/IMG_1606.gif?raw=true",
  "keywords": [
    "mageswap",
    "default"
  ],
  "tokens": [      
    {
      "name": "CHILL",
      "address": "0xe47d957F83F8887063150AaF7187411351643392",
      "symbol": "CHILL",
      "decimals": 18,
      "chainId": 250,
      "logoURI": "https://ftmscan.com/token/images/chillpill_32.png"
    },
    {
      "name": "LOVE",
      "address": "0xE388D77715f90Bcf4711EbDB6733A86A7ad79C36",
      "symbol": "LOVE",
      "decimals": 18,
      "chainId": 250,
      "logoURI": "https://raw.githubusercontent.com/mageswap/default-token-list/main/build/love-ftm-icon.png"
    },
    {
      "name": "Moon Bay",
      "address": "0xE5a4c0af6F5f7Ab5d6C1D38254bCf4Cc26d688ed",
      "symbol": "BAY",
      "decimals": 18,
      "chainId": 250,
      "logoURI": "https://equalizer.exchange/assets/logo/BAY.png"
    },
    {
      "name": "CULT",
      "address": "0x4Ed9C2aba77141433c462B8EE835B7fC39Ec449A",
      "symbol": "CULT",
      "decimals": 18,
      "chainId": 250,
      "logoURI": "https://www.gitbook.com/cdn-cgi/image/width=40,dpr=2,height=40,fit=contain,format=auto/https%3A%2F%2F1006326791-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252F4vvEhKAsLKJtRi6o6WKE%252Ficon%252Fp1aNhaPJklsFLET3pEqk%252Fskully-icon.png%3Falt%3Dmedia%26token%3D4b786e99-22dd-4af7-87e1-73382963d585"
    },
    {
      "name": "POTION",
      "address": "0x3edA36088b931098e8E472748840b3dF78268c72",
      "symbol": "POTION",
      "decimals": 18,
      "chainId": 250,
      "logoURI": "https://magepunks.xyz/assets/images/potion-icon.png"
    },
    {
      "name": "Surveyor DAO",
      "address": "0x5d9EaFC54567F34164A269Ba6C099068df6ef651",
      "symbol": "SURV",
      "decimals": 18,
      "chainId": 250,
      "logoURI": "https://ftmscan.com/token/images/surveyordao_32.png"
    },
    {
      "name": "USDC",
      "address": "0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf",
      "symbol": "USDC",
      "decimals": 6,
      "chainId": 250,
      "logoURI": "https://ftmscan.com/token/images/USDC_32.png"
    }
  ]
}


export interface ListsState {
  readonly byUrl: {
    readonly [url: string]: {
      readonly current: TokenList | null
      readonly pendingUpdate: TokenList | null
      readonly loadingRequestId: string | null
      readonly error: string | null
    }
  }
  // this contains the default list of lists from the last time the updateVersion was called, i.e. the app was reloaded
  readonly lastInitializedDefaultListOfLists?: string[]
  readonly selectedListUrl: string | undefined
}

const NEW_LIST_STATE: ListsState['byUrl'][string] = {
  error: null,
  current: null,
  loadingRequestId: null,
  pendingUpdate: null
}

type Mutable<T> = { -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? U[] : T[P] }

const initialState: ListsState = {
  lastInitializedDefaultListOfLists: DEFAULT_LIST_OF_LISTS,
  byUrl: {
    ...DEFAULT_LIST_OF_LISTS.reduce<Mutable<ListsState['byUrl']>>((memo, listUrl) => {
      memo[listUrl] = NEW_LIST_STATE
      return memo
    }, {}),
    [DEFAULT_TOKEN_LIST_URL]: {
      error: null,
      current: MAGESWAP_DEFAULT_LIST,
      loadingRequestId: null,
      pendingUpdate: null
    }
  },
  selectedListUrl: DEFAULT_TOKEN_LIST_URL
}

export default createReducer(initialState, builder =>
  builder
    .addCase(fetchTokenList.pending, (state, { payload: { requestId, url } }) => {
      state.byUrl[url] = {
        current: MAGESWAP_DEFAULT_LIST,
        pendingUpdate: null,
        ...state.byUrl[url],
        loadingRequestId: requestId,
        error: null
      }
    })
    .addCase(fetchTokenList.fulfilled, (state, { payload: { requestId, tokenList, url } }) => {
      const current = state.byUrl[url]?.current
      const loadingRequestId = state.byUrl[url]?.loadingRequestId

      // no-op if update does nothing
      if (current) {
        const upgradeType = getVersionUpgrade(current.version, tokenList.version)
        if (upgradeType === VersionUpgrade.NONE) return
        if (loadingRequestId === null || loadingRequestId === requestId) {
          state.byUrl[url] = {
            ...state.byUrl[url],
            loadingRequestId: null,
            error: null,
            current: current,
            pendingUpdate: tokenList
          }
        }
      } else {
        state.byUrl[url] = {
          ...state.byUrl[url],
          loadingRequestId: null,
          error: null,
          current: tokenList,
          pendingUpdate: null
        }
      }
    })
    .addCase(fetchTokenList.rejected, (state, { payload: { url, requestId, errorMessage } }) => {
      if (state.byUrl[url]?.loadingRequestId !== requestId) {
        // no-op since it's not the latest request
        return
      }

      state.byUrl[url] = {
        ...state.byUrl[url],
        loadingRequestId: null,
        error: errorMessage,
        current: null,
        pendingUpdate: null
      }
    })
    .addCase(selectList, (state, { payload: url }) => {
      state.selectedListUrl = url
      // automatically adds list
      if (!state.byUrl[url]) {
        state.byUrl[url] = NEW_LIST_STATE
      }
    })
    .addCase(addList, (state, { payload: url }) => {
      if (!state.byUrl[url]) {
        state.byUrl[url] = NEW_LIST_STATE
      }
    })
    .addCase(removeList, (state, { payload: url }) => {
      if (state.byUrl[url]) {
        delete state.byUrl[url]
      }
      if (state.selectedListUrl === url) {
        state.selectedListUrl = Object.keys(state.byUrl)[0]
      }
    })
    .addCase(acceptListUpdate, (state, { payload: url }) => {
      if (!state.byUrl[url]?.pendingUpdate) {
        throw new Error('accept list update called without pending update')
      }
      state.byUrl[url] = {
        ...state.byUrl[url],
        pendingUpdate: null,
        current: state.byUrl[url].pendingUpdate
      }
    })
    .addCase(updateVersion, state => {
      // state loaded from localStorage, but new lists have never been initialized
      if (!state.lastInitializedDefaultListOfLists) {
        state.byUrl = initialState.byUrl
        state.selectedListUrl = undefined
      } else if (state.lastInitializedDefaultListOfLists) {
        const lastInitializedSet = state.lastInitializedDefaultListOfLists.reduce<Set<string>>(
          (s, l) => s.add(l),
          new Set()
        )
        const newListOfListsSet = DEFAULT_LIST_OF_LISTS.reduce<Set<string>>((s, l) => s.add(l), new Set())

        DEFAULT_LIST_OF_LISTS.forEach(listUrl => {
          if (!lastInitializedSet.has(listUrl)) {
            state.byUrl[listUrl] = NEW_LIST_STATE
          }
        })

        state.lastInitializedDefaultListOfLists.forEach(listUrl => {
          if (!newListOfListsSet.has(listUrl)) {
            delete state.byUrl[listUrl]
          }
        })
      }

      state.lastInitializedDefaultListOfLists = DEFAULT_LIST_OF_LISTS
    })
)
